import React, { useEffect, useState } from "react";

const useWebsiteInfo = () => {
  const [is911vip, setIs911vip] = useState(false);
  const [is5285, setIs5285] = useState(false);
  const [isMkvip, setIsMkvip] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    setIs911vip(hostname.includes("911vip"));
    setIs5285(hostname.includes("5285"));
    setIsMkvip(hostname.includes("mkvip"));
  }, []);

  // 如果是 911vip.co 網域，is911vip 為 true
  // 如果是 5285.com.tw 網域，is5285 為 true
  return { is911vip, is5285, isMkvip };
};

export default useWebsiteInfo;
