import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { gameData } from "../helpers/gamelist";
import { COMMON_WEB_PATH, typeIconConfig } from "../helpers/stylesConfig";
import useWebsiteInfo from "../hooks/useWebsiteInfo";

const GameCategories = () => {
  const scrollRef = useRef(null);
  const location = useLocation();
  const { t, i18n: i18nInstance } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const { is911vip, is5285, isMkvip } = useWebsiteInfo();
  const currentLang = i18nInstance.language || "en";

  // 獲取當前語言的遊戲數據
  const currentGameData = gameData(currentLang);

  // 需要過濾的遊戲 UID
  const filteredGameUids = ["CR2401", "CR2404", "CR2405", "CR2406"];

  // mkvip 需要過濾的遊戲 UID
  const mkvipFilteredGameUids = ["SL2460", "SL2461", "SL2462"];

  // 在組件內部過濾遊戲
  const filteredGames = currentGameData.filter(
    (game) =>
      !(filteredGameUids.includes(game.uid) && (is911vip || is5285)) &&
      !(mkvipFilteredGameUids.includes(game.uid) && isMkvip)
  );

  // 取得所有不重複的遊戲類型
  const gameCategories = [...new Set(filteredGames.map((game) => game.type))];

  // 額外的連結
  const additionalLinks = [
    {
      id: "lobby",
      icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/lobby.svg`,
      hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/lobby-w.svg`,
      path: "/",
      title: "lobby",
    },
    {
      id: "all",
      icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/all.svg`,
      hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/all-w.svg`,
      path: "/products",
      title: "All Games",
    },
  ];

  // 加在最後面的Favorite分類
  const endLinks = [
    {
      id: "favorite",
      icon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/favorite.svg`,
      hoverIcon: `${COMMON_WEB_PATH}/WSKYgameSample/icon/favorite-w.svg`,
      path: "/favorite",
      title: "favorite",
    },
  ];

  // 合併所有分類
  const allCategories = [
    ...additionalLinks,
    ...gameCategories.map((category) => ({
      id: category,
      icon: typeIconConfig[category],
      hoverIcon: typeIconConfig[category]?.replace(".svg", "-w.svg"),
      path: `/products/${category}`,
      title: category,
      isGameCategory: true,
    })),
    ...endLinks,
  ];

  // 判斷是否為當前選中的分類
  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === path;
    }
    // 處理 All 分類 (僅當路徑完全符合 "/products" 或 "/products/" 時)
    if (path === "/products") {
      return (
        location.pathname === "/products" || location.pathname === "/products/"
      );
    }
    // 處理 Favorite 頁面
    if (path === "/favorite") {
      return location.pathname === "/favorite";
    }
    // 處理遊戲類別路徑 (例如 "/products/Slot")
    return location.pathname.startsWith(path);
  };

  return (
    <Box
      position="relative"
      width="100%"
      overflowX="auto"
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
      ref={scrollRef}
      py={2}
      bg="white"
      //boxShadow="0 2px 4px rgba(0,0,0,0.1)"
      display={{ base: "block", md: "none" }}
    >
      <HStack spacing={4} px={4} pb={1}>
        {allCategories.map((category) => {
          const active = isActive(category.path);

          return (
            <Link to={category.path} key={category.id}>
              <Flex
                align="center"
                justify="center"
                gap={2}
                // minW="65px"
                borderRadius="31px"
                py={4}
                px={3}
                bg={
                  active
                    ? "transparent linear-gradient(90deg, #49D599 0%, #6EEBBC 100%)"
                    : "#F6F6F8"
                }
                transition="all 0.2s"
                whiteSpace="nowrap"
              >
                <Box className="w-6 h-6 relative">
                  <Image
                    src={category.icon}
                    alt={category.title}
                    className={`w-6 h-6 absolute top-0 left-0 transition-opacity duration-200 ${
                      active ? "opacity-0" : ""
                    }`}
                  />
                  <Image
                    src={category.hoverIcon}
                    alt={category.title}
                    className={`w-6 h-6 absolute top-0 left-0 transition-opacity duration-200 ${
                      active ? "opacity-100" : "opacity-0"
                    }`}
                  />
                </Box>
                {active && (
                  <Text
                    fontSize="16px"
                    fontWeight="bold"
                    mt={1}
                    textAlign="center"
                    className="text-base-light-white2"
                  >
                    {i18n(category.title)}
                  </Text>
                )}
              </Flex>
            </Link>
          );
        })}
      </HStack>
    </Box>
  );
};

export default GameCategories;
